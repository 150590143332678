export default class CardDelivery {
  constructor(existingObject) {
    const object = existingObject || {};

    this.streetAddress1 = object.streetAddress1 || '';
    this.streetAddress2 = object.streetAddress2 || '';
    this.city = object.city || '';
    this.unit = object.unit || '';
    this.postalCode = object.postalCode || '';
    this.contactNumber = object.contactNumber || '';
    this.contactName = object.contactName || '';
    this.specialInstruction = object.specialInstruction || '';
    this.emailAddress = object.emailAddress || '';
    this.adress = object.adress || [];
  }
}
