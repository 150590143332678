export default class Attachment {
  constructor(existingObject) {
    const object = existingObject || {};
    this.serviceRequestId = object.serviceRequestId || '';
    this.id = object.id || '';
    this.base64Content = object.base64Content || '';
    this.contentType = object.contentType || '';
    this.filename = object.filename || '';
    this.lastModifiedDate = object.lastModifiedDate || null;
    this.contentLength = object.contentLength || 0;
    this.fileType = object.fileType || '';
  }
}
